
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from '@/views/shops/_form.vue';

import CustomerShopsModule from '@/store/customers/shops';
import ShopEntityModule from '@/store/shops/entity';

@Component({
  components: {
    TitleReturn,
    FormBlock,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId;
  },
})
export default class CustomerShopsAdd extends Form {
  customerId = '';

  get titlePage(): string {
    return CustomerShopsModule.pageSettings.titleAdd;
  }

  init(): void {
    ShopEntityModule.initAdd(this.customerId);
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers_shops', params: { customerId: this.customerId } });
  }

  mounted(): void {
    this.init();
  }
}
